import { useQuery } from 'react-query';
import { captureException } from '@sentry/nextjs';
import jwt_decode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { IdTokenService } from '~/src/services/id-token.service';
import { setIdToken, setIdTokenLifeTime } from '~/src/redux/id-token/id-token.slice';
import { selectIdToken, selectIdTokenLifeTime } from '~/src/redux/id-token/id-token.selectors';
import { setAuthStateCompletedAction, setCurrentUserAction } from '~/src/redux/user/user.slice';
import { isAllLocalStands } from '~/src/params';
import { NO_AUTH_CODE } from '~/src/models/constants/status-codes';
import { QUERY_KEY_TOKEN } from '~/src/repositories/id-token/keys';
import type { ExtendedAxiosResponse } from '~/src/models/types/axios-response.types';

interface DecodedTokenInterface {
    sub: string;
    description: string;
    type: string;
    phone: string;
    avatar: string;
    email: string;
    picture: string;
    phone_number?: string;
    name?: string;
    exp: number;
    iat: number;
}

export const useFetchGetIdToken = () => {
    const dispatch = useDispatch();
    const timeDifference_5min = 300_000;
    const idTokenFromStore = useSelector(selectIdToken);
    const idTokenLifeTime = useSelector(selectIdTokenLifeTime);
    // Время на протяжении которого токен будет использоваться повторно,
    // без дополнительных вызовов API на сервер (при повторных запросах)
    // Для production уменьшаем данное время, чтобы не вызвало коллизий с idTokenLifeTime при refetch
    // Для local увеличиваем данное время, потому что idTokenLifeTime для local равен 0
    const idTokenStaleTime = isAllLocalStands
        ? idTokenLifeTime + timeDifference_5min
        : idTokenLifeTime - timeDifference_5min;

    const { refetch } = useQuery(
        [QUERY_KEY_TOKEN],
        () => IdTokenService.getIdTokenHttp(),
        {
            onSuccess: (idToken) => {
                dispatch(setIdToken(idToken));
                const decodedToken: DecodedTokenInterface = jwt_decode(idToken);
                const { sub, description, type, phone, picture, email, name, phone_number, exp, iat } = decodedToken;
                const tokenLifeTimeInMs = (exp - iat) * 1000;

                dispatch(setIdTokenLifeTime((tokenLifeTimeInMs - timeDifference_5min) || 0));
                dispatch(setCurrentUserAction(
                    {
                        id: sub,
                        name: description || name || '',
                        abonent: null,
                        numberType: type,
                        accountType: null,
                        currentTariff: null,
                        phone: phone || phone_number || '',
                        picture,
                        email,
                        isUpdating: false,
                    }
                ));
            },
            onError: (error: ExtendedAxiosResponse<unknown>) => {
                // eslint-disable-next-line no-console
                console.log(QUERY_KEY_TOKEN, error);
                dispatch(setAuthStateCompletedAction(true));
                if (error?.response?.status === NO_AUTH_CODE) {
                    return;
                }
                captureException(error);
            },
            // Время жизни токена - через данное время делаем запрос на обновление токена (5 мин здесь учтены)
            refetchInterval: idTokenLifeTime,
            refetchIntervalInBackground: true,
            enabled: !!idTokenFromStore,
            retry: 1,
            retryDelay: 100,
            staleTime: idTokenStaleTime,
        },
    );

    return { refetch };
};