import React, { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsConnectedCurrentOrganizationInHeader, selectOrganizations } from '~/src/redux/user/user.selectors';
import { getNavItems } from '~/src/helpers/nav-items.helper';
import { isContainsStatusConnectedOrganisations } from '~/src/helpers/contains-status-connected-organisations';
import {
    appAuthConfig,
    headerEnv,
    logInOutEndpoints,
    urlsConfig,
} from '~/src/components/sections/header/header.constants';
// @ts-ignore
import toNext from '@prp/svelte-adapter/next';
// @ts-ignore
import { EcosystemHeader, EcosystemHeaderSsr } from '@prp/ecosystem-header/index';
import {
    setAuthStateCompletedAction,
    setUserPartyId,
    setUserProfileIsUpdating,
    setWorkingPartyId,
} from '~/src/redux/user/user.slice';
import { openWindow } from '~/src/helpers/open-window.helper';
import {
    sendAuthAnalytics,
    sendLogoHandlerAnalytics,
    sendMenuHeaderAnalytics,
} from '~/src/components/sections/header/header.analysts';
import { generateSearchParams, getActiveTab } from '~/src/components/sections/header/header.utils';
import { selectIsAuth } from '~/src/redux/id-token/id-token.selectors';
import { useIdToken } from '~/src/hooks/id-token.hook';
import { transliterate } from '~/src/helpers/transliterate.helper';
import { API_HOST_ENV, LOGOUT_ENV } from '~/src/params';
import { UserService } from '~/src/services/user.service';
import { NavItemsEnum } from '~/src/models/enums/nav-items.enum';
import { useMatchMedia } from '~/src/hooks/use-match-media.hook';
import { MediaWidthEnum } from '~/src/models/enums/media-width.enum';
import * as Styled from './header.styles';

const EcosystemHeaderNext = toNext(EcosystemHeader, EcosystemHeaderSsr, {}, 'div');

const Header: FC = () => {
    const router = useRouter();
    const dispatch = useDispatch();
    const [isMobile] = useMatchMedia(MediaWidthEnum.mobile);
    const isAuth = useSelector(selectIsAuth);
    const { refetchWithToken } = useIdToken();
    const isConnectedCurrentOrganizationInHeader = useSelector(selectIsConnectedCurrentOrganizationInHeader);
    const organizations = useSelector(selectOrganizations);
    const isNeedToAddBecomeAgent = !isAuth || !isContainsStatusConnectedOrganisations(organizations);
    const isNeedToInviteAgent = isAuth && isContainsStatusConnectedOrganisations(organizations);

    const [authIsFinishedLocal, setAuthIsFinishedLocal] = useState(false);

    const navItemsParams = [
        isConnectedCurrentOrganizationInHeader ? NavItemsEnum.needToAddClientList : NavItemsEnum.needToAddBecomeAgent,
        isNeedToInviteAgent ? NavItemsEnum.needToAddInviteAgent : NavItemsEnum.notShow,
    ];

    const onBusinessProfileChanged = async (event: CustomEvent) => {
        dispatch(setUserProfileIsUpdating(true));
        await UserService.postUserProfileUpdate();
        dispatch(setUserProfileIsUpdating(false));
        const partyId: string = event?.detail?.businessProfile?.partyId;
        partyId && dispatch(setWorkingPartyId(partyId));
    };

    const onProfileAuthorized = () => {
        setAuthIsFinishedLocal(true);
    };

    useEffect(() => {
        authIsFinishedLocal && isAuth && dispatch(setAuthStateCompletedAction(true));
    }, [authIsFinishedLocal, isAuth]);

    useEffect(() => {
        if (isAuth && authIsFinishedLocal) {
            sendAuthAnalytics();
        }
    }, [isAuth, authIsFinishedLocal]);

    const handleNavigate = async (event: CustomEvent) => {
        if (event.detail === '#') {
            return;
        }

        const targetItem = getNavItems(
            isConnectedCurrentOrganizationInHeader ? NavItemsEnum.needToAddClientList : NavItemsEnum.notShow,
            isNeedToAddBecomeAgent ? NavItemsEnum.needToAddBecomeAgent : NavItemsEnum.notShow,
        ).find((item) => item.href === event.detail);

        if (event.detail) {
            event.detail !== '/' && (await sendMenuHeaderAnalytics({ eventLabel: transliterate(targetItem?.label) }));
            event.detail === '/' && (await sendLogoHandlerAnalytics());
            if (targetItem?.targetBlank) {
                openWindow(targetItem.href);
            } else {
                await router.push({ pathname: event.detail, query: router.query });
            }
        }
    };

    const businessProfileInitHandle = async (event: CustomEvent) => {
        const { userPartyId, workingPartyId } = event.detail?.businessSession || {};
        dispatch(setUserPartyId(userPartyId));
        dispatch(setWorkingPartyId(workingPartyId));
    };

    return (
        <Styled.HeaderContainer>
            <Styled.HeaderWrapper>
                <EcosystemHeaderNext
                    mode="idtoken"
                    getIdToken={refetchWithToken}
                    env={headerEnv}
                    apiUrls={appAuthConfig}
                    authUrls={logInOutEndpoints}
                    urls={urlsConfig}
                    logoText={'Агенты'}
                    selectedNavItem={getActiveTab(router.pathname, isMobile, ...navItemsParams)}
                    navItems={getNavItems(...navItemsParams)}
                    onNavigate={handleNavigate}
                    onBusinessProfileChanged={onBusinessProfileChanged}
                    onBusinessProfileInited={businessProfileInitHandle}
                    onProfileAuthorized={onProfileAuthorized}
                    contentStyles={Styled.AdaptiveStyles}
                    disableRedirectToLogin={true}
                    thirdLevel={true}
                    logoutEndpoint={`${API_HOST_ENV}/logout?redirect=${LOGOUT_ENV}${generateSearchParams(
                        router.query,
                    )}`}
                    isLogoNavigate
                />
            </Styled.HeaderWrapper>
        </Styled.HeaderContainer>
    );
};

export default Header;
