import axios from 'axios';
import { captureException } from '@sentry/nextjs';
import { getRequestUrlHelper } from '~/src/helpers/get-request-url.helper';
import { getConfigSSR } from '~/src/helpers/cookie-ssr.helper';
import { PrivilegesEnum } from '~/src/models/enums/privileges.enum';
import {
    apiKeyAgentNameByCode,
    apiKeyCurrentReferralCode,
    apiKeyLeadCreationPrivileges,
    apiKeyStatusPrivileges,
    apiKeyUpdateUser,
    defaultUrlAgentNameByCode,
    defaultUrlCurrentReferralCode,
    defaultUrlLeadCreationPrivileges,
    defaultUrlStatusPrivileges,
    defaultUrlUpdateUser,
} from '~/src/repositories/user/api-urls';

export interface StatusPrivilegesInterface {
    partyId: string;
    privileges: Record<PrivilegesEnum, boolean>;
}

export const postUserProfileUpdate = async (isSSR?: boolean) => {
    const url = getRequestUrlHelper({ isSSR, apiKey: apiKeyUpdateUser, defaultUrl: defaultUrlUpdateUser });

    try {
        await axios.post(url);
    } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.log(error);
        captureException(error);
    }
};

export const getStatusPrivileges = async (isSSR?: boolean, cookies?: Partial<{ [key: string]: string }>) => {
    const url = getRequestUrlHelper({
        isSSR,
        apiKey: apiKeyStatusPrivileges,
        defaultUrl: defaultUrlStatusPrivileges,
    });

    try {
        const { data } = await axios.get<StatusPrivilegesInterface[]>(url, getConfigSSR(isSSR, cookies));
        return data;
    } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.log(error);
        captureException(error);
    }
};

export const getCreationLeadPrivileges = async (
    agentCode?: string,
    isSSR?: boolean,
    cookies?: Partial<{ [key: string]: string }>,
) => {
    const url = getRequestUrlHelper({
        isSSR,
        apiKey: apiKeyLeadCreationPrivileges,
        defaultUrl: defaultUrlLeadCreationPrivileges,
    });

    try {
        const { data } = await axios.get<boolean>(url, getConfigSSR(isSSR, cookies, { agentCode }));

        return data;
    } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.log(error);
        captureException(error);
        return false;
    }
};

export const getAgentCode = async (isSSR?: boolean) => {
    const url = getRequestUrlHelper({
        isSSR,
        apiKey: apiKeyCurrentReferralCode,
        defaultUrl: defaultUrlCurrentReferralCode,
    });

    try {
        const { data } = await axios.get<string>(url);
        return data;
    } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.log(error);
        captureException(error);
    }
};

export const getAgentNameByCode = async (agentCode: string, isSSR?: boolean) => {
    const url = getRequestUrlHelper({
        isSSR,
        apiKey: apiKeyAgentNameByCode,
        defaultUrl: defaultUrlAgentNameByCode,
    });

    const config = {
        params: {
            agentCode,
        },
    };

    try {
        const { data } = await axios.get<string>(url, config);
        return data;
    } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.log(error);
        captureException(error);
        return null;
    }
};
