import {
    getAgentCode,
    getAgentNameByCode,
    getStatusPrivileges,
    postUserProfileUpdate,
} from '~/src/repositories/user/user.repository';

export const UserService = {
    postUserProfileUpdate,
    getStatusPrivileges,
    getAgentCode,
    getAgentNameByCode,
};
