import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIdToken } from '~/src/redux/id-token/id-token.selectors';
import { setIsAuth } from '~/src/redux/id-token/id-token.slice';
import { useFetchGetIdToken } from '~/src/repositories/id-token/hooks/use-fetch-get-id-token.hook';

export const useIdToken = () => {
    const dispatch = useDispatch();
    const idTokenFromStore = useSelector(selectIdToken);
    const { refetch } = useFetchGetIdToken();

    const refetchWithToken = async () => {
        if (idTokenFromStore) {
            return idTokenFromStore;
        }
        const responseWithIdToken = await refetch();
        return responseWithIdToken?.data || '';
    };

    // if id token exist - user authorized, else - unauthorized
    useEffect(() => {
        dispatch(setIsAuth(Boolean(idTokenFromStore)));
    }, [idTokenFromStore]);

    return { refetchWithToken };
};
