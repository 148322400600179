import {
    API_HOST_ENV,
    CURRENT_HOST_ENV,
    isAgentsLocalStand,
    isAlphaBetaStands,
    isProduction,
    isSandbox,
    PART_LOGIN_PATH,
    LOGOUT_ENV,
    PROFILE_HOST_ENV,
    SER_CON_AGENTS_URL,
} from '~/src/params';

export const headerEnv = isProduction || isSandbox ? 'prod' : 'stage';

export const logInOutEndpoints = {
    [headerEnv as string]: {
        profile: {
            base: API_HOST_ENV,
            endpoints: {
                login: PART_LOGIN_PATH,
                logout: `/logout?redirect=${LOGOUT_ENV}`,
            },
        },
    },
};

const getB2BEndPoints = () => {
    const localAgentsEndPoints = {
        endpoints: {
            individuals: {
                getCurrent: 'profile/api/v2/individuals/current',
                getOrganizations: 'profile/api/v2/individuals/{partyId}/organizations',
            },
            sessions: {
                getCurrent: 'profile/api/v2/partyroles/sessions/current',
                updateCurrent: 'profile/api/v2/partyroles/sessions/current',
            },
        },
    };

    const endPointsDefault = {
        endpoints: {
            individuals: {
                getCurrent: 'widget-api-service/api/individuals/current',
                getOrganizations: 'widget-api-service/api/individuals/{partyId}/organizations',
            },
            sessions: {
                getCurrent: 'widget-api-service/api/partyroles/sessions/current',
                updateCurrent: 'widget-api-service/api/partyroles/sessions/current',
            },
        },
    };

    return isAgentsLocalStand ? localAgentsEndPoints : isAlphaBetaStands ? endPointsDefault : {};
};

export const appAuthConfig = {
    [headerEnv as string]: {
        b2b: {
            base: CURRENT_HOST_ENV,
            ...getB2BEndPoints(),
        },
        notifications: {
            base: PROFILE_HOST_ENV,
        },
    },
};

export const urlsConfig = {
    all: {
        b2b: {
            urls: {
                business: `${API_HOST_ENV}${SER_CON_AGENTS_URL}`,
            },
        },
    },
};
