import { WebAnalyticsEventsCustomDataInterface } from '~/src/models/web-analytics.model';
import { GtmService } from '~/src/services/web-analytics.service';
import { getAnalyticsEventData } from '~/src/helpers/web-analytics-events-data.helper';
import {
    WebAnalyticsActionGroupEnum,
    WebAnalyticsEventActionEnum,
    WebAnalyticsEventCategoryEnum,
    WebAnalyticsEventEnum,
} from '~/src/models/enums/web-analytics.enum';

export const sendMenuHeaderAnalytics = async (options: WebAnalyticsEventsCustomDataInterface) => {
    GtmService.send(
        getAnalyticsEventData({
            event: WebAnalyticsEventEnum.vntMain,
            eventCategory: WebAnalyticsEventCategoryEnum.menu,
            eventAction: WebAnalyticsEventActionEnum.menuClick,
            eventLabel: 'dobavit_esche_odnogo_kontragenta',
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
            ...options,
        }),
    );
};

export const sendLogoHandlerAnalytics = () => {
    return () => sendMenuHeaderAnalytics({
        event: WebAnalyticsEventEnum.vntCross,
        eventCategory: WebAnalyticsEventCategoryEnum.crossElements,
        eventAction: WebAnalyticsEventActionEnum.elementClick,
        eventLabel: 'logotip',
    });
};

export const sendAuthAnalytics = () => {
    GtmService.send(
        getAnalyticsEventData({
            event: WebAnalyticsEventEnum.vntLogin,
            eventCategory: WebAnalyticsEventCategoryEnum.authorization,
            eventAction: WebAnalyticsEventActionEnum.confirmed,
            eventLabel: 'uspeshnaya_avtorizaciya',
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
        }),
    );
};