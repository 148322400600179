import styled from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { Z_INDEX } from '~/src/styles/tokens/z-index';

export const HeaderContainer = styled.header`
    width: 100%;
    margin-bottom: ${rem(5)};
    z-index: ${Z_INDEX.top};

    .prp-header {

        &__content {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
`;

export const HeaderWrapper = styled.div`
    min-height: ${rem(52)};
`;

export const AdaptiveStyles = {
    all: {
        width: '100%',
        'max-width': `${rem(1920)}`,
    },
    [`(min-width: ${rem(1920)})`]: {
        'padding-left': `${rem(184)}!important`,
        'padding-right': `${rem(184)}!important`,
    },
    [`(max-width: ${rem(1919)})`]: {
        'padding-left': `${rem(160)}!important`,
        'padding-right': `${rem(160)}!important`,
    },
    [`(max-width: ${rem(1679)})`]: {
        'padding-left': `${rem(88)}!important`,
        'padding-right': `${rem(88)}!important`,
    },
    [`(max-width: ${rem(1439)})`]: {
        'padding-left': `${rem(56)}!important`,
        'padding-right': `${rem(56)}!important`,
    },
    [`(max-width: ${rem(1279)})`]: {
        'padding-left': `${rem(40)}!important`,
        'padding-right': `${rem(40)}!important`,
    },
    [`(max-width: ${rem(767)})`]: {
        'padding-left': `${rem(20)}!important`,
        'padding-right': `${rem(20)}!important`,
    },
};
