import { getNavItems } from '~/src/helpers/nav-items.helper';
import { NavItemsEnum } from '~/src/models/enums/nav-items.enum';
import type { ParsedUrlQuery } from 'querystring';

export const generateSearchParams = (data: ParsedUrlQuery) => {
    if (Object.keys(data).length) {
        return Object.entries(data).reduce((accumulator, [ key, value ], index) => {
            const firstLoop = 0;
            if (index === firstLoop) {
                return accumulator + key + `=${value}`;
            }
            return accumulator + `&${key}` + `=${value}`;
        }, '?');
    }
    return '';
};

export const getActiveTab = (path: string, isMobile: boolean, ...params: NavItemsEnum[]) => {
    for (const menuItem of getNavItems(...params)) {
        if (menuItem.href === path) {
            return menuItem;
        }

        if (menuItem.children) {
            for (const child of menuItem.children) {
                if (child.href === path && isMobile) {
                    return child;
                }

                if (child.href === path) {
                    return menuItem;
                }
            }
        }
    }
};
