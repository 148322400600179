export const apiKeyUpdateUser = 'api/next/user/update';
export const defaultUrlUpdateUser = 'api/v1/user/current/update';

export const apiKeyStatusPrivileges = 'api/next/user/privileges';
export const defaultUrlStatusPrivileges = 'api/v1/user/agents/privileges';

export const apiKeyLeadCreationPrivileges = 'api/next/user/privileges/lead-creation';
export const defaultUrlLeadCreationPrivileges = 'api/v1/user/agents/privileges/lead-creation';

export const apiKeyCurrentReferralCode = 'api/next/user/organizations/current/referral-code';
export const defaultUrlCurrentReferralCode = 'api/v1/user/organizations/current/referral-code';

export const apiKeyAgentNameByCode = 'api/next/user/agents/info';
export const defaultUrlAgentNameByCode = 'api/v1/user/agents/info';
